<template>
  <v-container
    fill-height
    fluid
    grid-list-xl>
    <v-layout
      justify-center
      wrap>
      <v-flex md12>
        <form>
          <!-- General Info -->
          <material-card
            :title="entity.type == EntityType.SUPPLIER ? $t('entity.supplierInfo') : $t('entity.agentInfo')"
            color="green"
            flat
            full-width
            class="bg-white"
          >
            <!-- Box Body -->
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation>
              <v-container py-0>
                <span
                  v-if="entity.parent"
                  class="font-large"
                >{{ $t("issueVoucherRequest.parent") }}:
                <b class="font-large">{{ entity.parent }}</b></span
                >
                <v-layout wrap>
                  <v-flex
                    xs12
                    sm8
                    md6>
                    <v-text-field
                      :rules="[ruleRequiredValue]"
                      v-model="entity.name"
                      :label="$t('actions.name')"
                      :name="$t('actions.name')"
                      :disabled="!permissionUpdateEntityInfo()"
                      type="text"
                      class="required"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    sm8
                    md6>
                    <v-text-field
                      :rules="[ruleRequiredValue, ruleValidCode]"
                      v-model="entity.code"
                      :label="$t('common.code')"
                      :name="$t('common.code')"
                      :disabled="!permissionUpdateEntityInfo()"
                      type="text"
                      class="required"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    sm8
                    md6>
                    <v-text-field
                      v-model="fullAddress"
                      :label="$t('userProfile.address')"
                      :name="$t('userProfile.address')"
                      :append-outer-icon="'mdi-dots-horizontal'"
                      readonly
                      type="text"
                      @click:append-outer="onEditAddress"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    sm8
                    md6>
                    <v-text-field
                      :rules="[ruleValidPhoneNumber]"
                      v-model="entity.phone"
                      :label="$t('userProfile.phone')"
                      :name="$t('userProfile.phone')"
                      :disabled="!permissionUpdateEntityInfo()"
                      type="text"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    sm8
                    md6>
                    <v-text-field
                      :rules="[ruleRequiredValue, ruleValidEmail]"
                      v-model="entity.email"
                      :label="$t('userProfile.email')"
                      :name="$t('userProfile.email')"
                      :disabled="!permissionUpdateEntityInfo()"
                      type="text"
                      class="required"
                    />
                  </v-flex>
                  <v-flex
                    v-if="entity.type==EntityType.SUPPLIER"
                    xs12
                    sm8
                    md6>
                    <v-text-field
                      :rules="[ruleValidUrlPath]"
                      v-model="entity.website"
                      :label="$t('common.website')"
                      :name="$t('common.website')"
                      :disabled="!permissionUpdateEntityInfo()"
                      type="text"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    sm8
                    md6>
                    <v-textarea
                      v-model="entity.description"
                      :label="$t('generalConfiguration.description')"
                      :name="$t('generalConfiguration.description')"
                      :disabled="!permissionUpdateEntityInfo()"
                      type="text"
                    />
                  </v-flex>
                  <v-flex
                    class="text-center"
                    xs12
                    md6
                  >
                    <image-picker
                      key="entity-img"
                      :image-width="240"
                      :image-height="90"
                      :image-url="entity.imageUrl"
                      exceptation-ratio="8:3"
                      max-size-upload="3MB"
                      recommend-dimension="80x30"
                      @onSelectedImageFile="onSelectBannerImg"
                      @onResetImage="onClickRemoveBannerImg"
                    />
                  </v-flex>
                  <v-flex
                    v-if="!forConasi && roleInEntity(EntityRoleType.MANAGER)"
                    xs12
                    sm8
                    md12>
                    <v-text-field
                      v-model="entity.accessCode"
                      :label="$t('development.accessCode.title')"
                      :name="$t('development.accessCode.title')"
                      :prepend-inner-icon="isEmptyString(entity.accessCode) ? null : 'mdi-content-copy'"
                      :append-outer-icon="'mdi-lock-reset'"
                      readonly
                      type="text"
                      @click:prepend-inner="opCopyClipboard(entity.accessCode)"
                      @click:append-outer="doResetKey()"
                    />
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
            <!-- /.Box Body -->
            <!-- Box Footer -->
            <v-flex
              xs12
              md12>
              <v-btn
                class="white--text"
                color="red"
                style="margin-right: 5px;"
                @click="onClose()"
              >
                <span class="btn-label">
                  <i
                    class="glyphicon glyphicon-remove"
                    style="margin-right: 5px;"
                  />
                </span>
                {{ $t("common.close") }}
              </v-btn>
              <v-btn
                v-if="permissionUpdateEntityInfo()"
                class="ma-2"
                color="success"
                style="margin-right: 5px;"
                @click="saveEntityInfo()"
              >
                <span class="btn-label">
                  <i
                    class="glyphicon glyphicon-ok"
                    style="margin-right: 5px;"
                  />
                </span>
                {{ $t("common.save") }}
              </v-btn>
            </v-flex>
            <v-flex
              v-if="entity.address.latitude && entity.address.longitude"
              xs12
              sm12
              md12
            >
              <iframe
                :src="`https://maps.google.com/maps?q=${entity.address.latitude}+${entity.address.longitude}&layer=tc&t=m&z=19&source=embed&output=svembed`"
                width="100%"
                height="500"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0" />
            </v-flex>
          </material-card>
        </form>
      </v-flex>
    </v-layout>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
    <update-address-modal
      ref="updateAddressModal"
      @onConfirm="onUpdateAddress"
    />
    <confirm-modal
      ref="confirmResetKeyModal"
      :title="$t('development.confirmResetKeyModal')"
      @onConfirm="onResetSecretKey"
    />
  </v-container>
</template>
<script>
import { FOR_CONASI_TARGET } from 'utils/constants'
import functionUtils from 'utils/functionUtils'
import validationRules from 'utils/validationRules'
import { mapActions, mapGetters } from 'vuex'
import EntityType from 'enum/entityType'
import EntityRoleType from 'enum/entityRoleType'
import ToastType from 'enum/toastType'
import axios from 'axios'
import UpdateAddressModal from 'Components/UpdateAddressModal'
import ImagePicker from 'Components/ImagePicker.vue'
import ConfirmModal from 'Components/ConfirmModal'
export default {
  components: {
    UpdateAddressModal,
    ImagePicker,
    ConfirmModal
  },
  data: () => ({
    valid: false,
    menu: false,
    EntityType: EntityType,
    EntityRoleType: EntityRoleType,
    birthDate: null,
    entity: {
      id: null,
      name: null,
      code: null,
      status: null,
      phone: null,
      email: null,
      website: null,
      type: null,
      description: null,
      parent: null,
      accessCode: null,
      imageUrl: null,
      imageFile: null,
      imageSize: null,
      address: {
        longitude: null,
        latitude: null,
        province: null,
        district: null,
        ward: null,
        street: null
      }
    },
    fullAddress: null,
    forConasi: FOR_CONASI_TARGET,
    media_server: {
      domain: '',
      token: ''
    }
  }),
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.birthDate)
    },
    ...mapGetters([
      'ENTITY_DETAIL_DATA',
      'GET_SNACK_BAR',
      'GET_CURRENT_ACCOUNT',
      'GET_TOKEN_MEDIA_SERVER_DATA',
      'GET_ACCESS_CODE_DATA'
    ])
  },
  watch: {
    GET_TOKEN_MEDIA_SERVER_DATA: function () {
      this.media_server.token = this.GET_TOKEN_MEDIA_SERVER_DATA.data.token
      this.media_server.domain = this.GET_TOKEN_MEDIA_SERVER_DATA.data.domain
    },
    ENTITY_DETAIL_DATA () {
      let data = this.ENTITY_DETAIL_DATA
      this.entity.id = data.id
      this.entity.name = data.name
      this.entity.code = data.code
      this.entity.phone = data.phone
      this.entity.description = data.description
      this.entity.email = data.email
      this.entity.website = data.website
      this.entity.parent = data.parent ? data.parent.name : ''
      this.entity.accessCode = data.access_code ? data.access_code : ''
      this.entity.imageUrl = data.image_url
      this.entity.address.longitude = data.longitude
      this.entity.address.latitude = data.latitude
      this.entity.address.province = data.province
      this.entity.address.district = data.district
      this.entity.address.ward = data.ward
      this.entity.address.street = data.street
      this.getFullAddress()
    },
    GET_ACCESS_CODE_DATA: function () {
      this.entity.accessCode = this.GET_ACCESS_CODE_DATA.access_code
    }
  },
  created () {
    this.entity.id = this.$route.params.id
    this.entity.type = sessionStorage.getItem('entityType')
    this.GET_DETAIL_ENTITY({ id: this.entity.id })
    this.GET_TOKEN_MEDIA_SERVER()
  },
  mounted () {},
  methods: {
    /**
     * Upload advertisement image
     */
    uploadBannerImage: async function () {
      let formData = new FormData()
      formData.append('file', this.entity.imageFile)
      formData.append('directory', 'eVoucher/Entity/Logo')
      formData.append('forceOverride', true)
      await axios({
        method: 'POST',
        timeout: 6000,
        url: this.media_server.domain + '/api/v1/upload/',
        data: formData,
        headers: { Authorization: `JWT ${this.media_server.token}` }
      }).then(
        async function (res) {
          let data = res.data
          this.entity.imageUrl = data.domain + data.url
        }.bind(this)
      ).catch(
        async function () {
          this.entity.imageUrl = null
        }.bind(this)
      )
    },
    /**
     * Select img
     */
    onSelectBannerImg: function (e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.entity.imageSize = files[0].size
      this.getResultFile(files[0])
      this.entity.imageUrl = URL.createObjectURL(files[0])
    },
    /**
     * Remove img
     */
    onClickRemoveBannerImg: function () {
      this.entity.imageUrl = null
      this.entity.imageFile = null
    },
    /**
     * Get result file
     */
    getResultFile: function (file) {
      let vm = this
      vm.entity.imageFile = file
    },
    /**
     * Required value
     */
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    ruleValidEmail (value) {
      return validationRules.ruleValidEmailFormat(value)
    },
    ruleValidPhoneNumber (value) {
      return validationRules.ruleValidPhoneNumber(value)
    },
    ruleValidCode (value) {
      return validationRules.ruleValidCode(value)
    },
    ruleValidUrlPath (value) {
      return validationRules.ruleValidUrlPath(value)
    },
    /**
     * Check has action
     */
    hasAction: function (metaAction) {
      return functionUtils.hasAction(this.GET_CURRENT_ACCOUNT, metaAction)
    },
    /**
     * Permission update entity info
     */
    permissionUpdateEntityInfo: function () {
      let entityType = this.entity.type ? parseInt(this.entity.type) : null
      if (entityType === EntityType.SUPPLIER && this.hasAction('update_supplier')) {
        return true
      } else if (entityType === EntityType.AGENT && this.hasAction('update_agent')) {
        return true
      } else if (entityType === EntityType.SITE && this.hasAction('update_site')) {
        return true
      }
      return false
    },
    roleInEntity: function (role) {
      return functionUtils.roleInEntity(role)
    },
    isNull: function (data) {
      return functionUtils.isNull(data)
    },
    isEmptyString: function (string) {
      return functionUtils.isEmptyString(string)
    },
    saveEntityInfo: async function () {
      if (this.$refs.form.validate()) {
        if (this.entity.imageSize > 3000000) {
          this.ON_SHOW_TOAST({
            message: this.$t('common.imageCannotExceed3MB'),
            styleType: 'danger'
          })
        } else {
          let filter = {
            name: this.entity.name,
            code: this.entity.code,
            phone: this.entity.phone,
            email: this.entity.email.trim().toLowerCase(),
            website: this.entity.website,
            description: this.entity.description ? this.entity.description : '',
            imageUrl: this.entity.imageUrl
          }
          if (this.entity.imageFile) {
            await this.uploadBannerImage()
            filter['imageUrl'] = this.entity.imageUrl
          }
          this.UPDATE_ENTITY({ id: this.entity.id, ...filter })
            .then(
              function () {
                this.GET_ENTITIES_OF_USER()
                this.ON_SHOW_TOAST({
                  message: this.$t('common.success'),
                  styleType: ToastType.SUCCESS
                })
              }.bind(this)
            )
            .catch(
              function (error) {
                if (!functionUtils.isNull(error.response.data.errorText)) {
                  let errorText = error.response.data.errorText
                  this.ON_SHOW_TOAST({
                    message: this.$t(`${errorText}`),
                    styletype: ToastType.ERROR
                  })
                } else {
                  this.ON_SHOW_TOAST({
                    message: this.$t('login.somethingWentWrong'),
                    styleType: ToastType.ERROR
                  })
                }
              }.bind(this)
            )
        }
      }
    },
    /**
     * Close
     */
    onClose: function () {
      window.history.back()
    },
    onEditAddress: function () {
      if (this.permissionUpdateEntityInfo()) {
        this.$refs.updateAddressModal.onShowModal(this.entity.address)
      }
    },
    onUpdateAddress: function (address) {
      if (!functionUtils.isNull(this.entity.id) && this.entity.id !== 0) {
        let filter = {
          longitude: address.longitude,
          latitude: address.latitude,
          province: address.province,
          district: address.district,
          ward: address.ward,
          street: address.street
        }
        this.UPDATE_ENTITY_ADDRESS({ id: this.entity.id, ...filter }).then(
          function () {
            this.ON_SHOW_TOAST({
              'message': this.$t('common.success'),
              'styleType': ToastType.SUCCESS
            })
          }.bind(this)
        ).catch(
          function () {
            this.ON_SHOW_TOAST({
              message: this.$t('login.somethingWentWrong'),
              styleType: ToastType.ERROR
            })
          }.bind(this)
        )
      }
      this.entity.address.province = address.province
      this.entity.address.district = address.district
      this.entity.address.ward = address.ward
      this.entity.address.longitude = address.longitude
      this.entity.address.latitude = address.latitude
      this.entity.address.street = address.street
      this.getFullAddress()
    },
    getFullAddress: function () {
      this.fullAddress = this.entity.address.street
      let addressFilter = {
        params: {
          countryCode: 84,
          cityCode: this.entity.address.province,
          districtCode: this.entity.address.district,
          wardCode: this.entity.address.ward
        }
      }
      this.GET_ADDRESS_DETAIL(addressFilter).then(
        function (res) {
          this.fullAddress = functionUtils.concatFullAddress(res.data, this.entity.address.street)
        }.bind(this)
      )
    },
    onResetSecretKey: function () {
      let data = {
        entityId: this.entity.id
      }
      this.CREATE_ACCESS_CODE(data).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            'styleType': ToastType.SUCCESS
          })
          let filter = {
            params: {
              entityId: this.entity.id
            }
          }
          this.GET_ACCESS_CODE(filter)
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    doResetKey: function () {
      if (functionUtils.isEmptyString(this.entity.accessCode)) {
        this.onResetSecretKey()
      } else {
        this.$refs.confirmResetKeyModal.onShowModal()
      }
    },
    opCopyClipboard: function (text) {
      navigator.clipboard.writeText(text)
      this.ON_SHOW_TOAST({
        'message': this.$t('common.copied'),
        'styleType': ToastType.SUCCESS
      })
    },
    ...mapActions([
      'GET_TOKEN_MEDIA_SERVER',
      'GET_DETAIL_ENTITY',
      'ON_SHOW_TOAST',
      'UPDATE_ENTITY',
      'GET_ENTITIES_OF_USER',
      'UPDATE_ENTITY_ADDRESS',
      'GET_ADDRESS_DETAIL',
      'CREATE_ACCESS_CODE',
      'GET_ACCESS_CODE'
    ])
  }
}
</script>
<style lang="scss" scoped>
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
